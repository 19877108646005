<template>
  <div v-if="selectedGeoIsEmpty"> Sélectionnes un magasin !</div>
  <loader v-else-if="loading && !selectedGeoIsEmpty" />
  <div v-else class="nomenclature">
    <div class="title">{{ $t('forecasts.title') }}</div>
    <div class="chips">
      <div class="info">{{ $t('forecasts.chips.overview') }}</div>
      <div v-for="overview in overviews"
           :key="overview"
           :class="[{ active: currentOverview === overview }, 'chip', overview]"
           @click="currentOverview = overview">
        {{ $t(`forecasts.overview.${ overview }`) }}
      </div>
    </div>
    <div v-if="geography.value?.[0]?.siteFormat === 'PRO'" class="warning-proxy">🚨 (Supermarchés) Les préconisations Opticam ne sont pas envoyés à METI 🚨</div>
    <div class="nomenclature-array">
      <div class="th nom">
        <div class="nom-label">{{ geography[0].siteLabel }}</div>
        <div class="search">
          <v-svg h="1.2rem" name="loupe" />
          <input v-model="searchTerm"
                 type="text"
                 class="input"
                 :placeholder="$t('search')">
        </div>
        <div class="favorites-filter">
          <v-svg v-for="(type, i) in favTypes"
                 :key="`fav-filter-${i}`"
                 :class="{ active: activeFavoriteTypes.includes(type) }"
                 h="1.2rem"
                 :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
                 @click="onFavoriteFilterClicked(type)" />
        </div>
      </div>
      <div v-for="day in daysInPeriod" :key="day" class="th day">{{ day }}</div>
      <div class="th action">{{ $t('forecasts.array.actions') }}</div>
      <template v-for="(market, i) in searchFilteredRows" :key="market.marketSid">
        <div :class="['td nom', { opened: openedNoms.market[market.marketSid] }]">
          <v-svg :key="i"
                 h="1rem"
                 :name="getFavoriteTypeOfItem('nomenclature', market) ? `${getFavoriteTypeOfItem('nomenclature', market)}-active` : 'star'"
                 @click="addOrUpdateFavorite('nomenclature', { market: market.market, marketLabel: market.marketLabel, marketSid: market.marketSid })" />
          <div class="label">{{ `${ market.market } - ${ market.marketLabel }` }}</div>
          <v-svg h="0.5rem" name="arrow-up" @click="onRowToggle({ level: 'market', item: market })" />
        </div>
        <div v-for="day in market.days"
             :key="`market-${market.marketSid}-day-${day.day}`"
             :class="[buildColorClassIndicator(day), 'td']">
          <span v-if="loadingMarket !== market.market">{{ $filters.formatNumber(buildDisplayIndicator(day[buildAttributeName], currentOverview), buildNumberOfDecimalIndicator(currentOverview), buildUnitIndicator(currentOverview), currentOverview === 'evolutionPercentSalesQuantity') }}
          </span>
          <span v-else class="skeleton" />
        </div>
        <div class="td action">
          <v-svg v-if="market.isPadlock" h="1rem" name="lock" @click="updatePadlock(market, i)" />
          <v-svg v-else h="1rem" name="unlock" @click="updatePadlock(market, i)" />
        </div>

        <div v-if="openedNoms.market[market.marketSid]" class="childs">
          <template v-for="(category, j) in market.childs" :key="category.categorySid">
            <div class="blank" />
            <div class="carriage">
              <v-svg h="1rem" :name="j === market?.childs?.length - 1 ? 'carriage-last' : 'carriage'" />
            </div>
            <div :class="['td nom', { opened: openedNoms.category[category.categorySid] }]">
              <div class="label">{{ `${ category.category } - ${ category.categoryLabel }` }}</div>
              <v-svg v-if="loadingFamilyRows[category.categorySid]" h="1rem" name="spinner" />
              <v-svg v-else
                     h="0.5rem"
                     name="arrow-up"
                     @click="onRowToggle({ level: 'category', item: category, parent: market })" />
            </div>
            <div v-for="day in category.days"
                 :key="`category-${category.categorySid}-day-${day.day}`"
                 :class="[ buildColorClassIndicator(day) ,'td']">
              <template v-if="!loadingCategoriesRows[market.market]">
                <v-svg v-if="day.isExceptionFamily" h="1rem" name="alert" />
                {{ $filters.formatNumber(buildDisplayIndicator(day[buildAttributeName], currentOverview), buildNumberOfDecimalIndicator(currentOverview), buildUnitIndicator(currentOverview), currentOverview === 'evolutionPercentSalesQuantity') }}
              </template>
              <span v-else class="skeleton" />
            </div>
            <div class="td action">
              <v-svg h="1rem" name="eye" @click="onShowMore({ level: 'category', item: category })" />
              <template v-if="$filters.userIsReam() || $filters.userIsAdmin() || $filters.isSameSite(userInfos, geography[0].site)">
                <v-svg h="1rem" name="pencil" @click="onRowEdit({ level: 'category', item: category })" />
                <v-svg v-if="category.isPadlock" h="1rem" name="lock" @click="updatePadlock(market, i, category)" />
                <v-svg v-else h="1rem" name="unlock" @click="updatePadlock(market, i, category)" />
              </template>
            </div>

            <row-details v-if="detailedRows.category[category.categorySid]" level="child" :days="category.days" />

            <row-edition v-if="editingNoms.category[category.categorySid]"
                         level="child"
                         :days="category.days"
                         :overview="currentOverview"
                         :category="category"
                         :geography-id="geography[0].site"
                         @fetchItemRow="fetchRows(market, i, category)" />

            <div v-if="openedNoms.market[market.marketSid] && openedNoms.category[category.categorySid] && category.childs?.length" class="grand-childs">
              <template v-for="(family, k) in category.childs" :key="k">
                <div class="blank" />
                <div v-if="i === searchFilteredRows.length - 1 && j === market?.childs?.length - 1" class="blank" />
                <div v-else class="carriage">
                  <v-svg h="1rem" name="carriage" />
                </div>
                <div class="blank" />
                <div class="carriage">
                  <v-svg h="1rem" :name="k === category?.childs?.length - 1 ? 'carriage-last' : 'carriage'" />
                </div>
                <div class="td nom">
                  <div class="label">{{ `${ family.family } - ${ family.familyLabel }` }}</div>
                </div>
                <div v-for="day in family.days"
                     :key="`family-${family.familySid}-day-${day.day}`"
                     class="td">
                  {{ $filters.formatNumber(buildDisplayIndicator(day[buildAttributeName], currentOverview), buildNumberOfDecimalIndicator(currentOverview), buildUnitIndicator(currentOverview), currentOverview === 'evolutionPercentSalesQuantity') }}
                </div>
                <div class="td action">
                  <v-svg h="1rem" name="eye" @click="onShowMore({ level: 'family', item: family })" />
                  <template v-if="$filters.userIsReam() || $filters.userIsAdmin() || $filters.isSameSite(userInfos, geography[0].site)">
                    <v-svg h="1rem" name="pencil" @click="onRowEdit({ level: 'family', item: family })" />
                  </template>
                </div>

                <row-details v-if="detailedRows.family[family.familySid]" level="grand-child" :days="family.days" />

                <row-edition v-if="editingNoms.family[family.familySid]"
                             level="grand-child"
                             :days="family.days"
                             :overview="currentOverview"
                             :category="category"
                             :family="family"
                             :geography-id="geography[0].site"
                             @fetchItemRow="fetchRows(market, i, category, family)" />
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { computed, inject, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useToast } from 'vue-toastification'
  import { extendWithAuthorization, http } from '@/http'
  import { DateTime } from 'luxon'
  import RowDetails from '@/components/nomenclature/RowDetails'
  import RowEdition from '@/components/nomenclature/RowEdition.vue'
  import useContextStore from '@/store/context.store'
  import { overviews, favoritesTypes } from '@/utils/CONF'
  import {
  buildColorClassIndicator,
  buildDisplayIndicator,
  buildNumberOfDecimalIndicator,
  buildUnitIndicator,
  removeAccents
  } from '@/utils/indicators.utils'

  export default {
    name: 'Nomenclature',
    components: { RowDetails, RowEdition },
    setup() {
      const toast = useToast()

      const contextStore = useContextStore()
      const { geography, getPeriodStartDateEndDate, period, addOrUpdateFavorite, getFavoriteTypeOfItem, getFavoritesByTypes } = contextStore

      const daysInPeriod = computed(() => {
        const date = DateTime.fromObject({ weekYear: period.value.year, weekNumber: period.value.weekNumber })
        return Array(7).fill().map((_, i) => date.startOf('week').plus({ day: i }).toFormat('ccc dd/LL'))
      })

      const currentOverview = ref(overviews[0])

      const rows = ref([])

      const loading = ref(false)

      const loadingCategoriesRows = ref({})

      const loadingMarket = ref(undefined)

      const fetchRows = async(market, index, category, family) => {
        if (geography.value?.length) {

          const params = { ...getPeriodStartDateEndDate.value  }
          if (market?.market) {
            params.nomLevel = 'market'
            params.nomValue = market?.market
            if (category?.categorySid) editingNoms.value.category[category?.categorySid] = false
            loadingCategoriesRows.value[market?.market] = true
            loadingMarket.value = market?.market
            if (family) {
              delete openedNoms.value?.category?.[category?.categorySid]
              // uncomment this line to close the editing panel under family
              // delete editingNoms.value.family[family.familySid]
            }
          } else {
            loading.value = true
          }

          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .get(`indicators/nomenclature/sites/${ geography.value[0].site }`, {searchParams: params} )
            .json()
            .then(res => {
              if(market?.market) {
                toast.success("Mise à jour effectuée avec succès")
                rows.value[index] = res[0]
              } else {
                rows.value = res
              }
            })
            .catch(err => {
              toast.error('Une erreur est survenue lors du chargement de la nomenclature')
              console.error(err)
            })

          if (family) await onRowToggle({ level: 'category', item: category, parent: market })
          loading.value = false
          loadingCategoriesRows.value[market?.market] = false
          loadingMarket.value = undefined
        } else {
          rows.value = []
        }
      }

      const emitter = inject("emitter")
      emitter.on('reloadData', () => {
        fetchRows()
        openedNoms.value = { market: {}, category: {} }
        editingNoms.value = { market: {}, category: {}, family: {} }
      })

      const fetchFamiliesRows = async category => {
        const extendedHttp = await extendWithAuthorization(http)
        return await extendedHttp
          .get(`indicators/nomenclature/sites/${ geography.value[0].site }/categories/${ category.category }`, { searchParams: { ...getPeriodStartDateEndDate.value, isVirtual: category.isVirtual } })
          .json()
          .then(res => res)
          .catch(err => {
            toast.error('Une erreur est survenue lors du chargement des familles')
            console.error(err)
          })
      }

      const searchTerm = ref(undefined)

      const searchFilteredRows = computed(() => {
        if (!searchTerm.value?.length) return favoriteFilteredRows.value
        return favoriteFilteredRows.value.reduce((acc, row) => {
          if (removeAccents(`${ row.market } - ${ row.marketLabel }`).includes(removeAccents(searchTerm.value))) acc.push(row)
          else row.childs.forEach(childRow => {
            if (removeAccents(`${ childRow.category } - ${ childRow.categoryLabel }`).includes(removeAccents(searchTerm.value))) {
              const rowIndexInAcc = acc.findIndex(accRow => accRow.marketSid === row.marketSid)
              if (rowIndexInAcc > -1) acc[rowIndexInAcc].childs = [...acc[rowIndexInAcc].childs, childRow]
              else acc.push({ ...row, childs: [childRow] })
            }
          })
          return acc
        }, [])
      })

      const activeFavoriteTypes = ref([])

      const favTypes = ref(favoritesTypes)

      const favoriteFilteredRows = computed(() => {
        if (!activeFavoriteTypes.value.length) return rows.value
        return rows.value.slice().filter(row => getFavoritesByTypes('nomenclature', activeFavoriteTypes.value).some(favoriteItem => favoriteItem.market === row.market))
      })

      const onFavoriteFilterClicked = type => {
        const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
        isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)
      }

      const route = useRoute()

      watch(() => ({ ...route.query }), () => {
        if (route.name === 'Nomenclature') fetchRows()
      })

      const selectedGeoIsEmpty = computed(() => !geography.value?.length)

      const buildAttributeName = computed(() => currentOverview.value.concat('GCH'))

      const loadingFamilyRows = ref({})

      const openedNoms = ref({
        market: {},
        category: {}
      })

      const onRowToggle = async({ level, item, parent }) => {
        if (level === 'category') {
          const marketIndex = rows.value.findIndex(market => market.marketSid === parent.marketSid)
          const categoryIndex = rows.value[marketIndex].childs.findIndex(category => category.categorySid === item.categorySid)

          if (marketIndex > -1 && categoryIndex > -1 && !rows.value[marketIndex].childs[categoryIndex].childs) {
            loadingFamilyRows.value[item.categorySid] = true
            rows.value[marketIndex].childs[categoryIndex].childs = await fetchFamiliesRows(item)
            delete loadingFamilyRows.value[item.categorySid]
          }
        }
        openedNoms.value[level][item[`${ level }Sid`]] = openedNoms.value[level][item[`${ level }Sid`]] ? !openedNoms.value[level][item[`${ level }Sid`]] : true
      }

      const editingNoms = ref({
        market: {},
        category: {},
        family: {}
      })

      const onRowEdit = ({ level, item }) =>
        editingNoms.value[level][item[`${ level }Sid`]] = editingNoms?.value?.[level]?.[item[`${ level }Sid`]] ? !editingNoms.value[level][item[`${ level }Sid`]] : true

      const detailedRows = ref({
        category: {},
        family: {}
      })

      const onShowMore = ({ level, item }) => detailedRows.value[level][item[`${ level }Sid`]] = detailedRows?.value?.[level]?.[item[`${ level }Sid`]] ? !detailedRows.value[level][item[`${ level }Sid`]] : true

      const userInfos = inject('userInfos')
      const updatePadlock = async(market, index, category) => {
        let params = { ...getPeriodStartDateEndDate.value,
          site: geography.value[0]?.site,
          userCode: userInfos?.employeeNumber,
          isPadlock: category ? category.isPadlock = !category.isPadlock : market.isPadlock = !market.isPadlock,
          categories: category ? [category.category] : market.childs.map(item => item.category)
        }
        const extendedHttp = await extendWithAuthorization(http)

         return await extendedHttp
           .post('indicators/forecasts/padlock', { json: params } )
           .json()
           .then(() => {
             toast.success('Mise à jour réalisée avec succès')
             fetchRows(market, index, category)
           })
           .catch(err => {
             toast.error('Une erreur est survenue')
             console.error(err)
           })

     }

      if (geography.value?.length) fetchRows()

      return {
        overviews,
        searchTerm,
        searchFilteredRows,
        currentOverview,
        openedNoms,
        onRowToggle,
        editingNoms,
        onRowEdit,
        rows,
        fetchRows,
        loadingFamilyRows,
        loadingCategoriesRows,
        loadingMarket,
        daysInPeriod,
        buildAttributeName,
        buildDisplayIndicator,
        buildNumberOfDecimalIndicator,
        buildUnitIndicator,
        buildColorClassIndicator,
        selectedGeoIsEmpty,
        geography,
        loading,
        addOrUpdateFavorite,
        getFavoriteTypeOfItem,
        activeFavoriteTypes,
        onFavoriteFilterClicked,
        favTypes,
        detailedRows,
        onShowMore,
        updatePadlock,
        userInfos
      }
    }
  }
</script>

<style scoped lang="scss">

  .nomenclature {
    width: 100%;
    display: flex;
    flex-direction: column;
    @extend %secondary-font--bold;
    font-size: $font-size-small;
    border: 1px solid #A6A6A6;
    border-radius: 16px;
    position: relative;
    margin: 1rem 0;

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-extra-big;
      position: absolute;
      top: -27px;
      left: 1rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
    }

    .chips {
      position: absolute;
      top: -10px;
      right: 1rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;

      .info {
        color: #A6A6A6;
        @extend %primary-font;
      }

      .chip {
        border: 1px solid var(--app-color);
        border-radius: 13px;
        color: var(--app-color);
        background-color: white;
        padding: 1px 7px;
        margin-left: 0.3rem;
        cursor: pointer;

        &.active {
          background-color: var(--app-color);
          color: white;
        }

        &.input {
          border: none;
          background-color: rgba(64, 64, 64, 1);
          color: white;
        }
      }
    }

    .warning-proxy {
      position: absolute;
      top: -10px;
      left: 15rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
    }

    .nomenclature-array {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      row-gap: 0.5rem;
      padding: 2rem 1rem 1rem 1rem;

      .th {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #404040;
        padding-bottom: 0.5rem;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 12;
        background-color: white;
        @extend %primary-font--bold;
        font-size: $font-size-small;

        &.action { justify-content: center; }

        &.day {
          flex-direction: column;
          align-items: center;
          word-spacing: 10000000000000000000px;
          text-align: center;
          text-transform: capitalize;
        }

        &.nom {
          .nom-label {
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 1rem;
            color: var(--app-color);
          }

          .v-svg { color: #808080; }

          .search {
            display: flex;
            align-items: center;
            margin: 0 0.5rem 0 auto;
            border-right: 2px solid #A6A6A6;

            input[type='text'] {
              margin-left: 0.5rem;
              width: 100px;
              border: none;
              outline: none;
              font-size: $font-size-small;
              @extend %secondary-font--bold;
              color: #808080;
            }
          }

          .favorites-filter {
            display: flex;
            align-items: center;
          }
        }
      }

      .td {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EFE3DB;
        padding: 4px 0;
        z-index: 9;

        &.nom { border-radius: 10px 0 0 10px; }

        &.action, &.blank {
          border-radius: 0 10px 10px 0;

          .svg-eye, .svg-pencil, .svg-unlock {
            cursor: pointer;
            color: #808080;
          }

          .svg-lock {
            cursor: pointer;
            color: #000000;
          }

          .svg-pencil, .svg-lock, .svg-unlock { margin-left: 0.5rem; }
        }

        &.double-restocking {
          font-weight: bold;
          color: var(--color-double-restocking);
        }

        &.user-value {
          font-weight: bold;
          color: var(--color-user-value);
        }

        &.multiplier {
          font-weight: bold;
          color: var(--color-multiplier);
        }

        &.limited {
          font-weight: bold;
          color: var(--color-limited);
        }

        &.forced {
          font-weight: bold;
          color: var(--color-forced);
        }

        .svg-alert { margin-right: 0.2rem; }

        .skeleton{
          border-radius: 7px;
          width: 30px;
          height: 18px;
          background-image: linear-gradient(90deg, #9E512147 0px, #9E512129 40px, #9E512147 80px);
          animation: shine-lines 1.6s infinite;

          @keyframes shine-lines {
            0% {
              background-position: -100px
            }
            40%, 100% {
              background-position: 140px
            }
          }
        }
      }

      .nom {
        justify-content: start;
        padding-left: 1rem;
        user-select: none;

        .v-svg {
          cursor: pointer;
          margin-right: 0.5rem;
        }

        .svg-spinner {
          margin: 0 0 0 0.2rem;
        }

        .svg-arrow-up { margin: -12px 0 0 0.3rem; }

        &.opened {
          .svg-arrow-up {
            transform: rotateX(180deg);
            margin: 10px 0 0 0.3rem;
          }
        }
      }

      .row-edition, .row-details {
        grid-column-start: 1;
        grid-column-end: -1;
      }

      .carriage {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .childs, .grand-childs {
        grid-column-start: 1;
        grid-column-end: -1;
        display: grid;
        row-gap: 0.5rem;
      }

      .childs {
        grid-template-columns: 20px 20px calc(40% - 40px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .td { background-color: #E4CFC1; }
      }

      .grand-childs {
        grid-template-columns: 20px 20px 20px 20px calc(40% - 80px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .td { background-color: #D1AC95; }
      }
    }

  }

</style>
