<template>
  <div :class="[level, 'row-details']">
    <div class="cell left">
      <div class="title">{{ $t(`rowDetails.uid`) }}</div>
    </div>
    <div v-for="(day, i) in days" :key="`days-${i}`" class="cell">
      <span>{{ day.username }}</span>
      <span>{{ day.processingTimestamp ? $filters.formatDate(day.processingTimestamp, 'dd/LL/yyyy') : '-'}}</span>
      <span>{{ day.processingTimestamp ? $filters.formatDate(day.processingTimestamp, 'HH:mm') : '-' }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RowDetails',
    props: {
      level: {
        type: String,
        required: true
      },
      days: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .row-details {
    display: grid;
    row-gap: 2rem;
    padding: 1rem 0 0.5rem 0;
    margin-top: -1rem;
    background-color: #EFE3DB;
    border-radius: 0 0 7px 7px;
    grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    &.child {
      background-color: #E4CFC1;
      margin: -1rem 0 0 40px;
      grid-template-columns: calc(40% - 1.5rem) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.grand-child {
      background-color: #D1AC95;
      margin: -1rem 0 0 80px;
      grid-template-columns: calc(40% - 3rem) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @extend %secondary-font--bold;

      &.left {
        align-items: flex-start;
        padding-left: 1rem;
      }
    }
  }
</style>
